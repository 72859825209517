import { START_DATE, useDatepicker } from '@datepicker-react/hooks';
import { Checkbox, CircularProgress, IconButton } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { AiOutlineMinus } from 'react-icons/ai';
import { BsArrowLeft, BsArrowRight, BsEyeSlash, BsFillFuelPumpFill } from 'react-icons/bs';
import { FaPhoneAlt } from 'react-icons/fa';
import { FaDownload, FaFilter, FaLocationDot, FaRegTrashCan, FaSortDown } from 'react-icons/fa6';
import { FcCheckmark } from 'react-icons/fc';
import { FiRefreshCw } from 'react-icons/fi';
import { GoCalendar } from 'react-icons/go';
import { IoIosArrowDropdown } from 'react-icons/io';
import { IoPersonCircle, IoSearchOutline } from 'react-icons/io5';
import { LuCalendarClock, LuUserSquare2 } from 'react-icons/lu';
import {
  MdCloudDownload,
  MdCreditCard,
  MdCreditCardOff,
  MdEmail,
  MdHotelClass,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdOutlineClear,
  MdOutlinePayment,
  MdPayment,
  MdTrackChanges,
} from 'react-icons/md';
import { PiSignpostFill } from 'react-icons/pi';
import { RiApps2Line } from 'react-icons/ri';
import {
  TbBuildingCommunity,
  TbBuildingEstate,
  TbBuildingFactory2,
  TbLayoutSidebarRightCollapse,
} from 'react-icons/tb';
import OutsideClickHandler from 'react-outside-click-handler';
import { db } from '../../../server/firebase';
import WorkSpace from '../../components/WorkSpace';
import DatepickerContext from '../new-booking/components/datepickerContext';
import Month1 from '../new-booking/components/Month1';
import ExportCustomer from './components/ExportCustomer';
import UpdateCustomerScreen from './components/UpdateCustomerScreen.js';

export default function Customer({ user }) {
  let newDate = new Date();
  const firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
  let date = (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
  const hour1 = (newDate.getHours() < 10 ? '0' : '') + newDate.getHours();
  const min1 = (newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes();
  const etdHour = +hour1.substring(0, 2) + 2;
  const finalEtd = `${etdHour}:${min1}`;
  const month = (newDate.getMonth() + 1 < 10 ? '0' : '') + (newDate.getMonth() + 1);
  const monthName = month[newDate.getMonth()];
  const year = newDate.getFullYear();
  let firstDayOfCurrentWeek = date - (newDate.getDay() < 10 ? '0' : '');
  let last = firstDayOfCurrentWeek + 6;
  let firstday = firstDayOfCurrentWeek - 6;

  let lastDayOfCurrentWeek = last < 10 ? `0${last}` : last;
  let firstDayOfLastWeek = firstday < 10 ? `0${firstday}` : firstday;
  const first = (firstDay.getDate() < 10 ? '0' : '') + firstDay.getDate();
  let yesterday = date - 1;
  let lastMonth = month - 1;
  let lastDay = new Date(year, lastMonth, 0);
  let firstDayOfYear = new Date(year, 0, 1);
  let lastPrevMonthDay = newDate.setMonth(newDate.getMonth(), 0);
  let prevMonth = lastMonth < 10 ? `0${lastMonth}` : lastMonth;
  const [searchCustomer, setSearchCustomer] = useState('');
  const [searchContact, setSearchContact] = useState('');
  const [searchMakh, setSearchMakh] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [statusInput, setStatusInput] = useState('');
  const [displayStatus, setDisplayStatus] = useState(false);
  const [displayCustomerStatus, setDisplayCustomerStatus] = useState(false);
  const [displayClass, setDisplayClass] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    focusedInput: START_DATE,
  });
  const [startDate, setStartDate] = useState(`${year}-${month}-${first}`);
  const [endDate, setEndDate] = useState(new Date().toISOString().substr(0, 10));
  const [displayDate, setDisplayDate] = useState(false);
  const [exporterName, setExporterName] = useState('');
  const [fileName, setFileName] = useState('');
  const [getPdf, setGetPdf] = useState([]);
  const [lastUpdateStatus, setLastUpdateStatus] = useState('');

  const [currentClass, setCurrentClass] = useState('');
  const [dateStatus, setDateStatus] = useState('Period');
  const [displayUpdate, setDisplayUpdate] = useState(false);
  const [updateStatus, setUpdateStatus] = useState('');
  const [staffInput, setStaffInput] = useState('');
  const [planInput, setPlanInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [sortCustomer, setSortCustomer] = useState(false);
  const [sortClass, setSortClass] = useState(false);
  const [sortLocation, setSortLocation] = useState(false);
  const [sortPerson, setSortPerson] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedShipment, setSelectedShipment] = useState([]);
  const [showCustomerDetail, setShowCustomerDetail] = useState(false);
  const [systemStatus, setSystemStatus] = useState('Very good');
  const [shipments, setShipments] = useState([]);
  const [displaySearchCustomerType, setDisplaySearchCustomerType] = useState(false);
  const [searchCustomerType, setSearchCustomerType] = useState('Name');

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(20);
  const [pageNumberLimit, setPageNumberLimit] = useState(10);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [maxPageLimit, setMaxPageLimit] = useState(10);
  const [filterMakh, setFilterMakh] = useState('');
  const [currencyExchange, setCurrencyExchange] = useState('');
  const [customerDetail, setCustomerDetail] = useState({});
  const [displayCustomerDetail, setDisplayCustomerDetail] = useState(false);
  const [displayCustomerShipment, setDisplayCustomerShipment] = useState(true);
  const [surchargeData, setSurchargeData] = useState([]);
  const [displayExport, setDisplayExport] = useState(false);
  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    focusedInput: dateRange.focusedInput,
    onDatesChange: handleDateChange,
  });

  function handleDateChange(data) {
    if (!data.focusedInput) {
      setDateRange({ ...data, focusedInput: START_DATE });
    } else {
      setDateRange(data);
    }
  }
  const handleToday = () => {
    setStartDate(`${year}-${month}-${date}`);
    setEndDate(`${year}-${month}-${date}`);
    setDisplayDate(false);
    setDateStatus('Today');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleYesterday = () => {
    if (yesterday === 0) {
      setStartDate(`${year}-${prevMonth}-${lastDay.getDate()}`);
      setEndDate(`${year}-${prevMonth}-${lastDay.getDate()}`);
      setDisplayDate(false);
      setDateStatus('Yesterday');
      setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
    } else {
      setStartDate(`${year}-${month}-${yesterday}`);
      setEndDate(`${year}-${month}-${yesterday}`);
      setDisplayDate(false);
      setDateStatus('Yesterday');
      setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
    }
  };
  const startDay = new Date(startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  const endDay = new Date(endDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  const handleApplyDate = () => {
    if (!dateRange.endDate) {
      setStartDate(`${selectYear}-${selectMonth}-${selectDate}`);
      setEndDate(`${selectYear}-${selectMonth}-${selectDate}`);
      setDisplayDate(false);
      setDateStatus('Period');
    } else {
      setStartDate(`${selectYear}-${selectMonth}-${selectDate}`);
      setEndDate(`${selectEndYear}-${selectEndMonth}-${selectEndDate}`);
      setDisplayDate(false);
      setDateStatus('Period');
    }
  };
  const d = new Date(dateRange.startDate);
  const selectMonth = (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1);
  const selectDate = (d.getDate() < 10 ? '0' : '') + d.getDate();
  const selectYear = d.getFullYear();

  const e = new Date(dateRange.endDate);
  const selectEndMonth = (e.getMonth() + 1 < 10 ? '0' : '') + (e.getMonth() + 1);
  const selectEndDate = (e.getDate() < 10 ? '0' : '') + e.getDate();
  const selectEndYear = e.getFullYear();
  const currentYear = new Date().getFullYear();
  const handleCancelDate = () => {
    setDisplayDate(false);
  };
  const handleShowDate = () => {
    setDisplayDate(!displayDate);
  };
  const handleCloseDate = () => {
    setDisplayDate(false);
  };
  const handleThisWeek = () => {
    setStartDate(`${year}-${month}-${firstDayOfCurrentWeek}`);
    setEndDate(`${year}-${month}-${lastDayOfCurrentWeek}`);
    setDisplayDate(false);
    setDateStatus('This week');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleLastWeek = () => {
    setStartDate(`${year}-${month}-${firstDayOfLastWeek}`);
    setEndDate(`${year}-${month}-${firstDayOfCurrentWeek}`);
    setDisplayDate(false);
    setDateStatus('Last week');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleThisMonth = () => {
    setStartDate(`${year}-${month}-${first}`);
    setEndDate(new Date(currentYear, new Date().getMonth() + 1).toISOString().split('T')[0]);
    setDisplayDate(false);
    setDateStatus('This month');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleLastMonth = () => {
    setStartDate(`${year}-${prevMonth}-01`);
    setEndDate(`${year}-${prevMonth}-${new Date(lastPrevMonthDay).getDate().toString()}`);
    setDisplayDate(false);
    setDateStatus('Last month');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleThisYear = () => {
    setStartDate(`${year}-01-01`);
    setEndDate(`${year}-12-31`);
    setDateStatus('This year');
    setDisplayDate(false);
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  let classRef = useRef(null);
  useEffect(() => {
    let handler = (e) => {
      if (!classRef.current.contains(e.target)) {
        setDisplayClass(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);
  function removeAccents(str) {
    var AccentsMap = [
      'aàảãáạăằẳẵắặâầẩẫấậ',
      'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
      'dđ',
      'DĐ',
      'eèẻẽéẹêềểễếệ',
      'EÈẺẼÉẸÊỀỂỄẾỆ',
      'iìỉĩíị',
      'IÌỈĨÍỊ',
      'oòỏõóọôồổỗốộơờởỡớợ',
      'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
      'uùủũúụưừửữứự',
      'UÙỦŨÚỤƯỪỬỮỨỰ',
      'yỳỷỹýỵ',
      'YỲỶỸÝỴ',
    ];
    for (var i = 0; i < AccentsMap.length; i++) {
      var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
      var char = AccentsMap[i][0];
      str = str.replace(re, char);
    }
    return str;
  }
  const getCurrentDateTime = () => {
    const options = { weekday: 'short', day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit' };
    const currentDate = new Date().toLocaleDateString('en-US', options);
    setLastUpdateStatus(currentDate);
  };
  const refreshSurcharge = () => {
    db.collection('surcharge')
      .doc('skd-surcharge')
      .get()
      .then((doc) => {
        if (doc.exists) {
          setSurchargeData(doc.data());
          setCurrencyExchange(doc.data().exchange);
        }
      });
  };

  useEffect(() => {
    refreshSurcharge();
  }, []);
  const refreshCustomer = () => {
    if (user.role === 'Courier') alert('You dont have access permission to this feature');
    setLoading(true);
    db.collection('users')
      .orderBy('timestamp', 'desc')
      .get()
      .then((snapshot) => {
        if (snapshot.empty) setLoading(false);
        setLoading(false);
        getCurrentDateTime();
        let data = snapshot.docs.map((doc) => doc.data());
        setCustomers(
          data
            .filter((s) => {
              return s.employee === 'no';
            })
            .filter((s) => {
              if (user.role === 'Sales') {
                return s.controller === user.displayName;
              } else {
                return s;
              }
            })
            .map((s) => {
              return {
                select: false,
                exporter: removeAccents(s.exporter),
                createAt: s.createAt,
                controller: s.controller,
                address: s.address,
                address2: s.address2,
                address3: s.address3,
                city: s.city,
                country: s.country,
                displayName: s.displayName,
                phone: s.phone,
                plan: s.plan,
                postal: s.postal,
                state: s.state,
                town: s.town,
                email: s.email,
                makh: s.makh,
                mst: s.mst,
                uid: s.uid,
              };
            })
        );
        setStaffs(
          data.filter((s) => {
            return s.employee === 'yes';
          })
        );
      })
      .catch((err) => {
        alert(err);
      });
  };
  const duplicateClass = [...new Set(customers.map((s) => s.plan))];
  useEffect(() => {
    refreshCustomer();
  }, []);
  // const sortCustomer = customers.sort((a, b) => {
  //   a = a.exporter || '';
  //   b = b.exporter || '';
  //   return a.localeCompare(b);
  // });
  const refresh = () => {
    setLoading(true);
    axios
      .get(`https://test.skydart.com.vn/api/shipmentttt/${startDate}/${endDate}/**/skd/shipment`)
      .then((snapshot) => {
        let data = snapshot.data;
        setShipments(
          data
            .filter((s, i) => {
              return data.map((val) => val.hawb).indexOf(s.hawb) == i;
            })
            .map((s, i) => {
              let fuelValue = ((+s.rate + +s.addressCorrection + +s.remote + +s.surcharge) * +s.fsc) / 100;
              let vatValue =
                ((+s.rate +
                  +s.addressCorrection +
                  +s.remote +
                  +s.surcharge +
                  +fuelValue +
                  +s.surcharge2 +
                  +s.comestic) *
                  +s.vat) /
                100;
              let total =
                +s.rate +
                +s.addressCorrection +
                +s.comestic +
                +s.remote +
                +s.surcharge +
                +fuelValue +
                +s.surcharge2 +
                +vatValue +
                +s.surcharge3;
              let removeSkd = s.hawb.substring(3);
              let otherCharge = +s.surcharge + +s.surcharge2 + +s.surcharge3;
              let fsc = (Math.round(fuelValue * 100) / 100).toFixed(2);
              let totalExtra = total;
              return {
                select: false,
                account: s.account || '',
                currency: s.currency || '',
                service: s.service || 'SKD',
                date: s.date || '',
                dateTime: `${new Date(s.date).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })} - ${s.time}`,
                hawb: s.hawb || '',
                removeSkd: removeSkd,
                mawb: s.mawb || '',
                makh: s.makh || '',
                fscExtra: fsc,
                totalExtra: totalExtra,
                company: s.company || '',
                contact: s.contact || '',
                type: s.type || '',
                status: s.status || '',
                term: s.term || '',
                time: s.time || '',
                address: s.address || '',
                address2: s.address2 || '',
                address3: s.address3 || '',
                city: s.city || '',
                otherCharge: otherCharge,
                country: s.country || '',
                phone: s.phone || '',
                postal: s.postal || '',
                state: s.state || '',
                term: s.term,
                town: s.town || '',
                chargeable: (Math.round(s.chargeable * 100) / 100).toFixed(2).toString() || '',
                amount: s.amount || '',
                totalItem: s.totalItem || '',
                transport: s.transport || '',
                description: s.description || '',
                controller: s.controller || '',
                eamFormat: s.eamFormat || '',
                value: s.value || '',
                airline: s.airline || '',
                reweight: (Math.round(s.reweight * 100) / 100).toFixed(2).toString() || '',
                agent: s.agent || '',
                etd: s.etd || '',
                subStatus: s.subStatus || '',
                sin: s.sin || '',
                sender: s.sender || '',
                note: s.note || '',
                perform: s.perform || '',
                rate: s.rate?.toString() || '',
                fsc: s.fsc || '',
                vat: s.vat.toString() || '',
                remote: s.remote || '0.00',
                comestic: s.comestic || '0.00',
                addressCorrection: s.addressCorrection || '0.00',
                totalValue: total.toFixed(2),
                surcharge: s.surcharge || '',
                surcharge2: s.surcharge2 || '',
                surcharge3: s.surcharge3 || '',
                fscValue: fuelValue.toFixed(2).toString() || '',
                vatValue: vatValue.toString() || '',
                sellNote: s.sellNote || '',
                paymentPickup: s.paymentPickup || '',
                paymentAcc: s.paymentAcc || '',
                paymentCompany: s.paymentCompany || '',
                fromEmail: s.fromEmail || '',
                fromCity: s.fromCity || '',
                com: s.com || '',
                rateUpdate: s.rateUpdate || '',
                paymentStatus: s.paymentStatus || '',
                paymentSubStatus: s.paymentSubStatus || '',
                code: s.code || '',
                eamService: s.eamService || '',
                pack: s.pack || '',
                accNote: s.accountNote,
                plan: s.plan || 'Pending',
              };
            })
        );
        setLoading(false);
      });
  };
  useEffect(() => {
    refresh();
  }, [startDate, endDate]);
  const finalCustomer = customers.filter((s) => {
    return (
      s.exporter.toLowerCase().includes(searchCustomer.toLowerCase()) &&
      s.makh.toLowerCase().includes(searchMakh.toLowerCase()) &&
      s.email.toLowerCase().includes(searchEmail.toLowerCase()) &&
      s.displayName.toLowerCase().includes(searchContact.toLowerCase())
    );
  });
  const pages = [];
  for (let i = 1; i <= Math.ceil(finalCustomer.length / itemPerPage); i++) {
    pages.push(i);
  }
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentCustomers = finalCustomer.slice(indexOfFirstItem, indexOfLastItem);

  const getShipment = shipments.filter((s) => {
    if (statusInput !== '') {
      return s.paymentStatus === statusInput;
    } else {
      return s;
    }
  });
  const finalShipment = getShipment.filter((s) => {
    return s.makh === filterMakh;
  });
  const debt = shipments.filter((s) => {
    return s.makh === filterMakh && s.paymentStatus === 'Un Paid';
  });
  const paid = shipments.filter((s) => {
    return s.makh === filterMakh && s.paymentStatus === 'Pay';
  });
  const totalUnpaid = debt.reduce((a, v) => (a = +a + +v.totalValue), 0);
  const totalPaid = paid.reduce((a, v) => (a = +a + +v.totalValue), 0);

  const totalAmount = finalShipment.reduce((a, v) => (a = +a + +v.totalValue), 0);
  const totalVND = totalAmount.toFixed(2) * currencyExchange;
  const totalRate = finalShipment.reduce((a, v) => (a = +a + +v.rate), 0);
  const totalWeight = finalShipment.reduce((a, v) => (a = +a + +v.chargeable), 0);
  const totalPieces = finalShipment.reduce((a, v) => (a = +a + +v.amount), 0);

  const totalReweight = finalShipment.reduce((a, v) => (a = +a + +v.reweight), 0);
  const totalPrice = finalShipment.reduce((a, v) => (a = +a + +v.totalValue), 0);
  const totalSurcharge3 = finalShipment.reduce((a, v) => (a = +a + +v.surcharge3), 0);
  const totalVatValue = finalShipment.reduce((a, v) => (a = +a + +v.vatValue), 0);
  const totalSurcharge2 = finalShipment.reduce((a, v) => (a = +a + +v.surcharge2), 0);
  const totalFscValue = finalShipment.reduce((a, v) => (a = +a + +v.fscValue), 0);
  const totalSurcharge1 = finalShipment.reduce((a, v) => (a = +a + +v.surcharge), 0);
  const totalComestic = finalShipment.reduce((a, v) => (a = +a + +v.comestic), 0);
  const totalRemote = finalShipment.reduce((a, v) => (a = +a + +v.remote), 0);
  const totalAddressCorrection = finalShipment.reduce((a, v) => (a = +a + +v.addressCorrection), 0);
  const handleChangePage = (e) => {
    setCurrentPage(+e.target.id);
  };
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageLimit + 1 && number > minPageLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleChangePage}
          className={currentPage == number ? 'active-page' : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });
  const handleFirstPage = () => {
    setCurrentPage(1);
    setMaxPageLimit(10);
    setMinPageLimit(0);
  };
  const handleLastPage = () => {
    setCurrentPage(pages[pages.length - 1]);
    setMaxPageLimit(pages.length);
    setMinPageLimit(pages.length - 5);
  };
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + pageNumberLimit);
      setMinPageLimit(minPageLimit + pageNumberLimit);
    }
  };
  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setMaxPageLimit(maxPageLimit - pageNumberLimit);
      setMinPageLimit(minPageLimit - pageNumberLimit);
    }
  };

  const selected = customers.filter((s) => {
    return s.select;
  });

  const handleCloseUpdate = () => {
    setDisplayUpdate(false);
  };

  const handleHideShipment = (makh) => {
    setSelectedCustomer(
      customers.map((sd) => {
        if (sd.makh === makh) {
          sd.hide = !sd.hide;
        }
        return sd;
      })
    );
  };
  const handleShowAll = (makh) => {
    setSelectedCustomer(
      customers.map((sd) => {
        if (sd.makh === makh) {
          sd.showAll = !sd.showAll;
        }
        return sd;
      })
    );
  };
  const handleUpdateNewController = async () => {
    setLoadingAction(true);
    setSelectedCustomer(
      selected.map((customer, index) => {
        const shipmentPerCustomer = getShipment.filter((filterShipment) => {
          return filterShipment.makh === customer.makh;
        });
        setSelectedShipment(
          shipmentPerCustomer?.map((shipments) => {
            shipments.loading = true;
          })
        );
        db.collection('users')
          .doc(customer.email)
          .update({
            controller: staffInput,
          })
          .then(async () => {
            const batchSize = 5;
            const totalShipments = shipmentPerCustomer?.length;
            let index = 0;
            while (index < totalShipments) {
              const batch = shipmentPerCustomer?.slice(index, index + batchSize);
              await Promise.all(
                batch.map(async (s) => {
                  try {
                    await axios
                      .put('https://test.skydart.com.vn/api/update/shipment/controller', {
                        hawb: s.hawb,
                        controller: staffInput,
                      })
                      .then(() => {
                        setSelectedShipment(
                          shipmentPerCustomer?.map((shipments) => {
                            shipments.updateStatus = 'true';
                            shipments.loading = false;
                            shipments.controller = staffInput;
                            return shipments;
                          })
                        );
                        if (index === selected.length - 1) {
                          setLoadingAction(false);
                        }
                      });
                  } catch (error) {
                    // Handle error
                    setSelectedShipment(
                      shipmentPerCustomer?.map((shipments) => {
                        shipments.updateStatus = 'false';
                        shipments.updateContent = `Error updating shipment: ${error}`;
                        shipments.loading = false;
                        return shipments;
                      })
                    );
                    if (index === selected.length - 1) {
                      setLoadingAction(false);
                    }
                  }
                })
              );

              index += batchSize;
            }
          });
      })
    );
  };
  const handleUpdateNewClass = async () => {
    setLoadingAction(true);
    setSelectedCustomer(
      selected.map((customer, index) => {
        const shipmentPerCustomer = getShipment.filter((filterShipment) => {
          return filterShipment.makh === customer.makh;
        });
        setSelectedShipment(
          shipmentPerCustomer?.map((shipments) => {
            shipments.loading = true;
          })
        );
        db.collection('users')
          .doc(customer.email)
          .update({
            plan: planInput,
          })
          .then(async () => {
            const batchSize = 5;
            const totalShipments = shipmentPerCustomer?.length;
            let index = 0;
            while (index < totalShipments) {
              const batch = shipmentPerCustomer?.slice(index, index + batchSize);
              await Promise.all(
                batch.map(async (s) => {
                  try {
                    await axios
                      .put('https://test.skydart.com.vn/api/update/shipment/plan', {
                        hawb: s.hawb,
                        plan: planInput,
                      })
                      .then(() => {
                        setSelectedShipment(
                          shipmentPerCustomer?.map((shipments) => {
                            shipments.updateStatus = 'true';
                            shipments.loading = false;
                            shipments.plan = planInput;
                            return shipments;
                          })
                        );
                        if (index === selected.length - 1) {
                          setLoadingAction(false);
                        }
                      });
                  } catch (error) {
                    // Handle error
                    setSelectedShipment(
                      shipmentPerCustomer?.map((shipments) => {
                        shipments.updateStatus = 'false';
                        shipments.updateContent = `Error updating shipment: ${error}`;
                        shipments.loading = false;
                        return shipments;
                      })
                    );
                    if (index === selected.length - 1) {
                      setLoadingAction(false);
                    }
                  }
                })
              );

              index += batchSize;
            }
          });
      })
    );
  };
  const planArray = [
    { name: 'FWD' },
    { name: 'FWD0' },
    { name: 'FWD0-TTN' },
    { name: 'FWD0-TTT' },
    { name: 'FWD1' },
    { name: 'FWD1-TTN' },
    { name: 'FWD1-TTT' },
    { name: 'FWD2' },
    { name: 'FWD2-TTN' },
    { name: 'FWD2-TTT' },
    { name: 'FWD3' },
    { name: 'FWD3-TTN' },
    { name: 'FWD3-TTT' },
    { name: 'Retail' },
    { name: 'Retail-1' },
    { name: 'FAN-SG' },
    { name: 'FWD-TTN' },
    { name: 'FWD-TTT' },
    { name: 'Retail-TTN' },
    { name: 'Retail-TTT' },
  ];
  const headers = [
    { label: 'CUSTOMER', key: 'sender' },
    { label: 'DATE', key: 'date' },
    { label: 'HAWB', key: 'hawb' },
    { label: 'SIN AWB', key: 'sin' },
    { label: 'DESTINATION', key: 'country' },
    { label: 'SERVICE', key: 'service' },
    { label: 'TYPE', key: 'type' },
    { label: 'WEIGHT', key: 'chargeable' },
    { label: 'REWEIGHT', key: 'reweight' },
    { label: 'RATE', key: 'rate' },
    { label: 'Comestic', key: 'comestic' },
    { label: 'Wrong Address', key: 'addressCorrection' },
    { label: 'Remote', key: 'remote' },
    { label: 'Extra 1', key: 'surcharge' },
    { label: 'FSC', key: 'fscValue' },
    { label: 'Extra 2', key: 'surcharge2' },
    { label: 'VAT', key: 'vatValue' },
    { label: 'Extra 3', key: 'surcharge3' },
    { label: 'TOTAL', key: 'totalValue' },
    { label: 'NOTE', key: 'accNote' },
    { label: 'NOTE 2', key: 'sellNote' },
  ];

  const excelFileName = fileName.replaceAll(' ', '-');
  return (
    <div className="w-full">
      <WorkSpace
        refreshShipment={refreshCustomer}
        loading={loading}
        lastUpdateStatus={lastUpdateStatus}
        finalShipment={customers}
        systemStatus={systemStatus}
        currentRoute="Customers"
      />
      {displayUpdate && (
        <UpdateCustomerScreen
          updateStatus={updateStatus}
          shipments={getShipment}
          selected={selected}
          handleCloseUpdate={handleCloseUpdate}
          handleHideShipment={handleHideShipment}
          staffs={staffs}
          plan={planArray}
          staffInput={staffInput}
          setStaffInput={setStaffInput}
          planInput={planInput}
          setPlanInput={setPlanInput}
          handleUpdateNewController={handleUpdateNewController}
          handleUpdateNewClass={handleUpdateNewClass}
          loading={loadingAction}
        />
      )}
      {displayExport && (
        <ExportCustomer
          startDay={startDay}
          endDay={endDay}
          monthName={monthName}
          setDisplayExport={setDisplayExport}
          setGetPdf={setGetPdf}
          fileName={fileName}
          setFileName={setFileName}
          exporterName={exporterName}
          currencyExchange={currencyExchange}
          getPdf={getPdf}
          finalShipment={finalShipment}
          totalWeight={totalWeight}
          totalReweight={totalReweight}
          totalRate={totalRate}
          totalComestic={totalComestic}
          totalAddressCorrection={totalAddressCorrection}
          totalRemote={totalRemote}
          totalSurcharge1={totalSurcharge1}
          totalFscValue={totalFscValue}
          totalSurcharge2={totalSurcharge2}
          totalVatValue={totalVatValue}
          totalSurcharge3={totalSurcharge3}
          totalAmount={totalAmount}
          totalVND={totalVND}
          headers={headers}
          excelFileName={excelFileName}
          startDate={startDate}
          endDate={endDate}
          customerDetail={customerDetail}
        />
      )}

      <div className="p-1 flex items-center pt-0">
        <div className="w-full bg-white rounded-[10px] m-2 flex items-center shadow-[rgba(0,0,0,0.1)_0px_1px_1px_0px,rgba(0,0,0,0.05)_0px_1px_2px_0px]">
          <OutsideClickHandler onOutsideClick={() => {}}>
            <div className="relative flex items-center">
              <IoSearchOutline size={23} className="ml-3" />
              <input
                type="text"
                placeholder="Search and filter customers"
                className="rounded-full border-solid border-slate-300 border-[1px] m-[3px] p-1 ml-2 px-2.5 w-[280px] search-column-input"
                value={
                  searchCustomerType === 'Name'
                    ? searchCustomer
                    : searchCustomerType === 'MAKH'
                      ? searchMakh
                      : searchCustomerType === 'Email'
                        ? searchEmail
                        : searchContact
                }
                onChange={(e) => {
                  searchCustomerType === 'MAKH'
                    ? setSearchMakh(e.target.value)
                    : searchCustomerType === 'Email'
                      ? setSearchEmail(e.target.value)
                      : searchCustomerType === 'Contact name'
                        ? setSearchContact(e.target.value)
                        : setSearchCustomer(e.target.value);
                }}
              />
            </div>
          </OutsideClickHandler>
          <p className="mx-1.5 ml-auto text-slate-500 text-[15px]">Search type:</p>
          <OutsideClickHandler
            onOutsideClick={() => {
              setDisplaySearchCustomerType(false);
            }}
          >
            <div className="relative mr-1.5">
              <div
                className="p-1 flex items-center font-semibold cursor-pointer hover:opacity-70 active:opacity-50 select-none"
                onClick={() => {
                  setDisplaySearchCustomerType(!displaySearchCustomerType);
                }}
              >
                <p className="mx-1.5">By {searchCustomerType}</p>
                <FaSortDown size={22} className="mt-[-10px]" />
              </div>
              {displaySearchCustomerType && (
                <div className="absolute bg-white mt-2 shadow-[rgba(17,17,26,0.05)_0px_4px_16px,rgba(17,17,26,0.05)_0px_8px_32px] z-[9999] w-[180px] ml-1.5 rounded-[7px]">
                  <div
                    className="p-1.5 text-[14px] font-semibold rounded-[10px] hover:bg-slate-100 active:bg-slate-200 m-1.5 select-none cursor-pointer"
                    onClick={() => {
                      setDisplaySearchCustomerType(false);
                      setSearchCustomerType('Name');
                      setSearchEmail('');
                      setSearchCustomer('');
                      setSearchMakh('');
                      setSearchContact('');
                    }}
                  >
                    <p>By Name</p>
                  </div>
                  <div
                    className="p-1.5 text-[14px] font-semibold rounded-[10px] hover:bg-slate-100 active:bg-slate-200 m-1.5 select-none cursor-pointer"
                    onClick={() => {
                      setDisplaySearchCustomerType(false);
                      setSearchCustomerType('Contact name');
                      setSearchEmail('');
                      setSearchContact('');
                      setSearchCustomer('');
                      setSearchMakh('');
                    }}
                  >
                    <p>By Contact name</p>
                  </div>
                  <div
                    className="p-1.5 text-[14px] font-semibold rounded-[10px] hover:bg-slate-100 active:bg-slate-200 m-1.5 select-none cursor-pointer"
                    onClick={() => {
                      setDisplaySearchCustomerType(false);
                      setSearchCustomerType('MAKH');
                      setSearchEmail('');
                      setSearchCustomer('');
                      setSearchMakh('');
                      setSearchContact('');
                    }}
                  >
                    <p>By MAKH</p>
                  </div>
                  <div
                    className="p-1.5 text-[14px] font-semibold rounded-[10px] hover:bg-slate-100 active:bg-slate-200 m-1.5 select-none cursor-pointer"
                    onClick={() => {
                      setDisplaySearchCustomerType(false);
                      setSearchCustomerType('Email');
                      setSearchEmail('');
                      setSearchCustomer('');
                      setSearchMakh('');
                      setSearchContact('');
                    }}
                  >
                    <p>By Email</p>
                  </div>
                </div>
              )}
            </div>
          </OutsideClickHandler>
        </div>
        <div className="w-fit mr-2.5 whitespace-nowrap">
          <OutsideClickHandler
            onOutsideClick={() => {
              handleCloseDate();
            }}
          >
            <div className="relative">
              <div
                className="flex p-2 bg-slate-200 rounded-[10px] hover:bg-slate-300 active:bg-slate-400 select-none transition-[0.1s] cursor-pointer"
                onClick={() => handleShowDate()}
              >
                <GoCalendar size={24} />
                <p style={{ marginRight: 7, marginLeft: 7 }}>{dateStatus}:</p>
                <p style={{ marginRight: 7 }}>{startDay}</p>
                <AiOutlineMinus size={20} style={{ marginRight: 7 }} />
                <p style={{ marginRight: 7 }}>{endDay}</p>
                <IoIosArrowDropdown size={24} />
              </div>
              {displayDate && (
                <div className="skd-user-date" style={{ right: 0 }}>
                  <DatepickerContext.Provider
                    value={{
                      focusedDate,
                      isDateFocused,
                      isDateSelected,
                      isDateHovered,
                      isDateBlocked,
                      isFirstOrLastSelectedDate,
                      onDateSelect,
                      onDateFocus,
                      onDateHover,
                    }}
                  >
                    <div className="skd-user-date-content">
                      <div className="skd-user-date-left">
                        <div
                          className={
                            dateStatus === 'Today'
                              ? 'skydart-date-range-right-option1'
                              : 'skydart-date-range-right-option'
                          }
                          onClick={handleToday}
                        >
                          {dateStatus === 'Today' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                          <p style={{ color: 'black' }}>Today</p>
                        </div>
                        <div
                          className={
                            dateStatus === 'Yesterday'
                              ? 'skydart-date-range-right-option1'
                              : 'skydart-date-range-right-option'
                          }
                          onClick={handleYesterday}
                        >
                          {dateStatus === 'Yesterday' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                          <p style={{ color: 'black' }}>Yesterday</p>
                        </div>
                        <div
                          className={
                            dateStatus === 'This week'
                              ? 'skydart-date-range-right-option1'
                              : 'skydart-date-range-right-option'
                          }
                          onClick={handleThisWeek}
                        >
                          {dateStatus === 'This week' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                          <p style={{ color: 'black' }}>This week</p>
                        </div>
                        <div
                          className={
                            dateStatus === 'Last week'
                              ? 'skydart-date-range-right-option1'
                              : 'skydart-date-range-right-option'
                          }
                          onClick={handleLastWeek}
                        >
                          {dateStatus === 'Last week' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                          <p style={{ color: 'black' }}>Last week</p>
                        </div>
                        <div
                          className={
                            dateStatus === 'This month'
                              ? 'skydart-date-range-right-option1'
                              : 'skydart-date-range-right-option'
                          }
                          onClick={handleThisMonth}
                        >
                          {dateStatus === 'This month' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                          <p style={{ color: 'black' }}>This Month</p>
                        </div>
                        <div
                          className={
                            dateStatus === 'Last month'
                              ? 'skydart-date-range-right-option1'
                              : 'skydart-date-range-right-option'
                          }
                          onClick={handleLastMonth}
                        >
                          {dateStatus === 'Last month' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                          <p style={{ color: 'black' }}>Last Month</p>
                        </div>
                        <div
                          className={
                            dateStatus === 'This year'
                              ? 'skydart-date-range-right-option1'
                              : 'skydart-date-range-right-option'
                          }
                          onClick={handleThisYear}
                        >
                          {dateStatus === 'This year' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                          <p style={{ color: 'black' }}>This Year</p>
                        </div>
                      </div>
                      <div className="skd-user-date-right">
                        <div
                          style={{
                            display: 'flex',
                            margin: '15px 0 0',
                            gridTemplateColumns: `repeat(${activeMonths.length}, 300px)`,
                            gridGap: '0 24px',
                          }}
                        >
                          <div className="date-month-back" onClick={goToPreviousMonths}>
                            <BsArrowLeft />
                          </div>
                          <div className="date-month-back1" onClick={goToNextMonths}>
                            <BsArrowRight />
                          </div>
                          {activeMonths.map((month) => (
                            <Month1
                              key={`${month.year}-${month.month}`}
                              year={month.year}
                              month={month.month}
                              firstDayOfWeek={firstDayOfWeek}
                            />
                          ))}
                        </div>
                        <div className="skd-user-date-shown">
                          <div className="skd-user-date-shown-range">
                            <h5 style={{ fontWeight: 450 }}>
                              {!dateRange.startDate ? 'Start Date' : dateRange.startDate.toDateString()}
                            </h5>
                            <AiOutlineMinus />
                            <h5 style={{ fontWeight: 450, marginLeft: 7 }}>
                              {!dateRange.endDate ? 'End Date' : dateRange.endDate.toDateString()}
                            </h5>
                            <p style={{ fontSize: 10 }}>Ho Chi Minh City time</p>
                          </div>
                          <div className="skd-user-date-update-button">
                            <div className="skd-user-date-update-cancel" onClick={handleCancelDate}>
                              <h5 style={{ color: 'black', textAlign: 'center' }}>Cancel</h5>
                            </div>
                            <div className="skd-user-date-update-succeed" onClick={handleApplyDate}>
                              <h5 style={{ color: 'white', textAlign: 'center' }}>Update</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </DatepickerContext.Provider>
                </div>
              )}
            </div>
          </OutsideClickHandler>
        </div>
      </div>
      <div className="relative">
        {displayCustomerDetail && (
          <div className="absolute right-0 top-0 z-[7777] flex h-full">
            <div
              className="mr-1.5 bg-white shadow-[rgba(0,0,0,0.18)_0px_2px_4px] w-[calc(100vw_-_45rem)]"
              style={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
            >
              <div
                className="p-2.5 flex items-center"
                style={{
                  borderBottom: '1px solid rgb(230,230,230)',
                }}
              >
                <p className="font-semibold text-2xl text-slate-600">Shipments list</p>
                <div className="relative ml-auto">
                  <div
                    className="p-2 flex items-center rounded-[10px] bg-slate-100 hover:bg-slate-200 active:bg-slate-300 font-semibold px-3.5 select-none cursor-pointer gap-1.5 w-[230px]"
                    onClick={() => {
                      setDisplayCustomerStatus(!displayCustomerStatus);
                    }}
                  >
                    <MdPayment size={23} />
                    <p>{statusInput !== '' ? `${statusInput} shipments` : 'Payment status'}</p>
                    <FaSortDown size={23} className="mt-[-10px] ml-auto" />
                  </div>
                  {displayCustomerStatus && (
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setDisplayCustomerStatus(false);
                      }}
                    >
                      <div className="absolute shadow-[rgba(50,50,105,0.15)_0px_2px_5px_0px,rgba(0,0,0,0.05)_0px_1px_1px_0px] bg-white mt-2 rounded-[10px] w-full z-[9999]">
                        <div
                          className="p-1.5 m-1 rounded-[10px] flex items-center justify-between hover:bg-slate-100 active:bg-slate-200 cursor-pointer"
                          onClick={() => {
                            setStatusInput('');
                            setDisplayCustomerStatus(false);
                          }}
                        >
                          <p>All</p>
                          <div className="w-[23px] h-[23px] rounded-full border-slate-300 border-solid border-[1px] flex items-center justify-center select-none">
                            {statusInput === '' && <div className="w-[13px] h-[13px] rounded-full bg-sky-600"></div>}
                          </div>
                        </div>
                        <div
                          className="p-1.5 m-1 rounded-[10px] flex items-center justify-between hover:bg-slate-100 active:bg-slate-200 cursor-pointer"
                          onClick={() => {
                            setStatusInput('Un Paid');
                            setDisplayCustomerStatus(false);
                          }}
                        >
                          <p>Un Paid</p>
                          <div className="w-[23px] h-[23px] rounded-full border-slate-300 border-solid border-[1px] flex items-center justify-center select-none">
                            {statusInput === 'Un Paid' && (
                              <div className="w-[13px] h-[13px] rounded-full bg-sky-600"></div>
                            )}
                          </div>
                        </div>
                        <div
                          className="p-1.5 m-1 rounded-[10px] flex items-center justify-between hover:bg-slate-100 active:bg-slate-200 cursor-pointer"
                          onClick={() => {
                            setStatusInput('Pay');
                            setDisplayCustomerStatus(false);
                          }}
                        >
                          <p>Pay</p>
                          <div className="w-[23px] h-[23px] rounded-full border-slate-300 border-solid border-[1px] flex items-center justify-center select-none">
                            {statusInput === 'Pay' && <div className="w-[13px] h-[13px] rounded-full bg-sky-600"></div>}
                          </div>
                        </div>
                      </div>
                    </OutsideClickHandler>
                  )}
                </div>
                <div className="p-2 rounded-[10px] bg-slate-100 cursor-pointer hover:bg-slate-200 active:bg-slate-300 active:scale-95 ml-1.5">
                  <TbLayoutSidebarRightCollapse size={23} />
                </div>
              </div>
              <div className="flex flex-col overflow-auto h-[calc(100vh_-_19.7rem)]">
                <div className="flex w-fit">
                  <div className="flex sticky left-0 bg-white z-10 shadow-[5px_0_5px_-2px_rgba(0,0,0,0.15)]">
                    <div className="flex p-1.5 border-solid border-[1px] border-slate-200 w-[200px]">
                      <RiApps2Line size={20} className="text-slate-500" />
                      <p className="text-[15px] font-semibold mx-1.5 text-[darkblue]">SKD HAWB</p>
                    </div>
                    <div className="flex p-1.5 border-solid border-[1px] border-slate-200 w-[200px]">
                      <RiApps2Line size={20} className="text-slate-500" />
                      <p className="text-[15px] font-semibold mx-1.5">Ship To</p>
                    </div>
                  </div>
                  <div className="flex p-1.5 border-solid border-[1px] border-slate-200 w-[250px]">
                    <RiApps2Line size={20} className="text-slate-500" />
                    <p className="text-[15px] font-semibold mx-1.5">Date</p>
                  </div>
                  <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[200px]">
                    <RiApps2Line size={20} className="text-slate-500" />
                    <p className="text-[15px] font-semibold mx-1.5">INTL AWB</p>
                  </div>
                  <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[100px]">
                    <RiApps2Line size={20} className="text-slate-500" />
                    <p className="text-[15px] font-semibold mx-1.5">Type</p>
                  </div>
                  <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[150px]">
                    <RiApps2Line size={20} className="text-slate-500" />
                    <p className="text-[15px] font-semibold mx-1.5">Weight (KG)</p>
                  </div>
                  <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[150px]">
                    <RiApps2Line size={20} className="text-slate-500" />
                    <p className="text-[15px] font-semibold mx-1.5">Reweight (KG)</p>
                  </div>
                  <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[150px]">
                    <RiApps2Line size={20} className="text-slate-500" />
                    <p className="text-[15px] font-semibold mx-1.5">Rate (USD)</p>
                  </div>
                  <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[150px]">
                    <RiApps2Line size={20} className="text-slate-500" />
                    <p className="text-[15px] font-semibold mx-1.5">Extra (USD)</p>
                  </div>
                  <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[180px]">
                    <RiApps2Line size={20} className="text-slate-500" />
                    <p className="text-[15px] font-semibold mx-1.5">Add correct (USD)</p>
                  </div>
                  <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[150px]">
                    <RiApps2Line size={20} className="text-slate-500" />
                    <p className="text-[15px] font-semibold mx-1.5">Remote (USD)</p>
                  </div>
                  <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[150px]">
                    <RiApps2Line size={20} className="text-slate-500" />
                    <p className="text-[15px] font-semibold mx-1.5">Extra 1 (USD)</p>
                  </div>
                  <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[150px]">
                    <RiApps2Line size={20} className="text-slate-500" />
                    <p className="text-[15px] font-semibold mx-1.5">FSC (USD)</p>
                  </div>
                  <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[150px]">
                    <RiApps2Line size={20} className="text-slate-500" />
                    <p className="text-[15px] font-semibold mx-1.5">Extra 2 (USD)</p>
                  </div>
                  <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[150px]">
                    <RiApps2Line size={20} className="text-slate-500" />
                    <p className="text-[15px] font-semibold mx-1.5">VAT (USD)</p>
                  </div>
                  <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[150px]">
                    <RiApps2Line size={20} className="text-slate-500" />
                    <p className="text-[15px] font-semibold mx-1.5">Extra 3 (USD)</p>
                  </div>
                  <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[150px]">
                    <RiApps2Line size={20} className="text-slate-500" />
                    <p className="text-[15px] font-semibold mx-1.5 text-rose-500">Total (USD)</p>
                  </div>
                </div>
                {finalShipment?.map((item7, index) => (
                  <div className="flex w-fit hover:bg-slate-200 active:bg-slate-300 cursor-pointer group">
                    <div className="flex sticky left-0 bg-white z-10 group-hover:bg-slate-200 group-active:bg-slate-300 shadow-[5px_0_5px_-2px_rgba(0,0,0,0.15)]">
                      <div className="flex p-1.5 border-solid border-[1px] border-slate-200 w-[200px]">
                        <p className="w-[24px] h-[23px] bg-slate-200 rounded-full flex items-center justify-center text-center">
                          {index + 1}
                        </p>
                        <p className="text-[15px] mx-1.5 text-[darkblue] w-full text-ellipsis overflow-hidden">
                          {item7.hawb}
                        </p>
                      </div>
                      <div className="flex p-1.5 border-solid border-[1px] border-slate-200 w-[200px]">
                        <img
                          src={`https://flagsapi.com/${item7.code.toUpperCase()}/shiny/64.png`}
                          className="w-[20px] h-[20px]  object-cover"
                        />
                        <p className="text-[15px] mx-1.5">{item7.country}</p>
                      </div>
                    </div>
                    <div className="flex p-1.5 border-solid border-[1px] border-slate-200 w-[250px]">
                      <LuCalendarClock size={20} className="text-slate-500" />
                      <p className="text-[15px] mx-1.5">{item7.dateTime}</p>
                    </div>
                    <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[200px]">
                      <p className="text-[15px] mx-0.5">{item7.sin}</p>
                    </div>
                    <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[100px]">
                      <p className="text-[15px] mx-0.5">{item7.type}</p>
                    </div>
                    <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[150px]">
                      <p className="text-[15px] mx-0.5">{item7.chargeable}</p>
                    </div>
                    <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[150px]">
                      <p className="text-[15px]  mx-0.5">{item7.reweight}</p>
                    </div>
                    <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[150px]">
                      <p className="text-[15px]  mx-0.5">{item7.rate}</p>
                    </div>
                    <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[150px]">
                      <p className="text-[15px] mx-0.5">{item7.comestic}</p>
                    </div>
                    <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[180px]">
                      <p className="text-[15px] mx-0.5">{item7.addressCorrection}</p>
                    </div>
                    <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[150px]">
                      <p className="text-[15px] mx-0.5">{item7.remote}</p>
                    </div>
                    <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[150px]">
                      <p className="text-[15px] mx-0.5">{item7.surcharge}</p>
                    </div>
                    <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[150px]">
                      <p className="text-[15px] mx-0.5">{item7.fscValue}</p>
                    </div>
                    <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[150px]">
                      <p className="text-[15px] mx-0.5">{item7.surcharge2}</p>
                    </div>
                    <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[150px]">
                      <p className="text-[15px] mx-0.5">{item7.vatValue}</p>
                    </div>
                    <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[150px]">
                      <p className="text-[15px] mx-0.5">{item7.surcharge3}</p>
                    </div>
                    <div className="flex  p-1.5 border-solid border-[1px] border-slate-200 w-[150px]">
                      <p className="text-[15px] mx-0.5 text-rose-500">{item7.totalValue}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="p-2">
                <p className="font-medium text-slate-600">
                  Found <span>{finalShipment.length}</span> shipments
                </p>
              </div>
            </div>
            <div
              className="bg-white w-[500px] h-full mr-3.5 shadow-[rgba(0,0,0,0.18)_0px_2px_4px] overflow-y-auto"
              style={{ borderTopRightRadius: 10, borderBottomRightRadius: 10 }}
            >
              <div
                className="p-2.5 flex items-start justify-between sticky top-0 bg-white z-10"
                style={{ borderBottom: '1px solid rgb(230,230,230)' }}
              >
                <div className="">
                  <p className="text-2xl font-semibold">{customerDetail?.exporter}</p>
                  <p className="text-[14px] text-slate-500">
                    from {startDay} to {endDay}
                  </p>
                  <p className="text-[14px] text-slate-500">MAKH: {customerDetail?.makh}</p>
                  <p className="text-[14px] text-[purple]">Tax ID: {customerDetail?.mst || 'Unauthorized'}</p>
                </div>
                <div className="flex items-center gap-x-1.5">
                  <div
                    className="w-[35px] h-[35px] rounded-[10px] bg-slate-100 hover:bg-slate-200 active:bg-slate-300 flex items-center justify-center cursor-pointer"
                    onClick={() => {
                      setDisplayExport(true);
                      setFilterMakh(customerDetail.makh);
                    }}
                  >
                    <FaDownload size={25} />
                  </div>
                  <div
                    className="w-[35px] h-[35px] rounded-[10px] bg-slate-100 hover:bg-slate-200 active:bg-slate-300 flex items-center justify-center cursor-pointer"
                    onClick={() => {
                      setDisplayCustomerDetail(false);
                      setFilterMakh('');
                      setCustomerDetail({});
                    }}
                  >
                    <MdOutlineClear size={30} />
                  </div>
                </div>
              </div>
              <div className="p-2.5 flex flex-col gap-y-2">
                <div className="flex items-center p-1.5 px-3.5 rounded-full bg-slate-100 border-slate-200 border-solid border-[1px]">
                  <IoPersonCircle size={25} />
                  <p className="ml-2">{customerDetail?.displayName}</p>
                </div>
                <div className="flex items-center p-1.5 px-3.5 rounded-full bg-slate-100 border-slate-200 border-solid border-[1px]">
                  <FaPhoneAlt size={25} />
                  <p className="ml-2">{customerDetail?.phone}</p>
                </div>
                <div className="flex items-center p-1.5 px-3.5 rounded-full bg-slate-100 border-slate-200 border-solid border-[1px]">
                  <MdEmail size={25} />
                  <p className="ml-2">{customerDetail?.email}</p>
                </div>
                <p className="text-xl font-semibold tracking-wide text-[darkblue] mt-3">Location</p>
                <div className="flex flex-col gap-y-2">
                  <div className="flex items-center p-1.5 px-3.5 rounded-full bg-slate-100 border-slate-200 border-solid border-[1px]">
                    <img src={`https://flagsapi.com/VN/shiny/64.png`} className="w-[25px] h-25px] " />
                    <p className="ml-2">{customerDetail?.country}</p>
                  </div>
                  <div className="flex items-center p-1.5 px-3.5 rounded-full bg-slate-100 border-slate-200 border-solid border-[1px]">
                    <TbBuildingCommunity size={25} />
                    <p className="ml-2">
                      <span className="text-slate-400">City: </span>
                      {customerDetail.city}
                    </p>
                  </div>
                  <div className="flex items-center p-1.5 px-3.5 rounded-full bg-slate-100 border-slate-200 border-solid border-[1px]">
                    <TbBuildingEstate size={25} />
                    <p className="ml-2">
                      <span className="text-slate-400">District: </span>
                      {customerDetail.town}
                    </p>
                  </div>
                  <div className="flex items-center p-1.5 px-3.5 rounded-full bg-slate-100 border-slate-200 border-solid border-[1px]">
                    <TbBuildingFactory2 size={25} />
                    <p className="ml-2">
                      <span className="text-slate-400">Ward: </span>
                      {customerDetail.state}
                    </p>
                  </div>
                  <div className="flex items-center p-1.5 px-3.5 rounded-full bg-slate-100 border-slate-200 border-solid border-[1px] group relative">
                    {' '}
                    <FaLocationDot size={25} />
                    <p className="ml-2 w-full whitespace-nowrap overflow-hidden text-ellipsis">
                      {customerDetail?.address}
                    </p>
                    <div className="absolute  z-10 bg-white text-black font-semibold tracking-wide px-2 rounded-[10px] hidden group-hover:block shadow-[rgba(0,0,0,0.1)_0px_1px_3px_0px,rgba(0,0,0,0.06)_0px_1px_2px_0px]">
                      {customerDetail?.address}
                    </div>
                  </div>
                  <div className="flex items-center p-1.5 px-3.5 rounded-full bg-slate-100 border-slate-200 border-solid border-[1px]">
                    <PiSignpostFill size={25} />
                    <p className="ml-2">
                      <span className="text-slate-400">Postal: </span>70500
                    </p>
                  </div>
                </div>
                <p className="text-xl font-semibold tracking-wide text-[darkblue] mt-3">
                  Summary for <span className="underline">{statusInput}</span>
                </p>
                <div className="mt-1.5 flex items-center justify-between">
                  <p className="text-slate-500">Total Weight:</p>
                  <p className="font-semibold">{(Math.round(totalWeight * 100) / 100).toFixed(2)} KG</p>
                </div>
                <div className="mt-1.5 flex items-center justify-between">
                  <p className="text-slate-500">Total Reweight:</p>
                  <p className="font-semibold">{(Math.round(totalReweight * 100) / 100).toFixed(2)} KG</p>
                </div>
                <div className="mt-1.5 flex items-center justify-between">
                  <p className="text-slate-500">Total Rate:</p>
                  <p className="font-semibold">
                    {totalRate.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD
                  </p>
                </div>
                <div className="mt-1.5 flex items-center justify-between">
                  <p className="text-slate-500">Total Extra:</p>
                  <p className="font-semibold">
                    {totalComestic.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}{' '}
                    USD
                  </p>
                </div>
                <div className="mt-1.5 flex items-center justify-between">
                  <p className="text-slate-500">Total Address Correction:</p>
                  <p className="font-semibold">
                    {totalAddressCorrection.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{' '}
                    USD
                  </p>
                </div>
                <div className="mt-1.5 flex items-center justify-between">
                  <p className="text-slate-500">Total Remote:</p>
                  <p className="font-semibold">
                    {totalRemote.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD
                  </p>
                </div>
                <div className="mt-1.5 flex items-center justify-between">
                  <p className="text-slate-500">Total Extra 1:</p>
                  <p className="font-semibold">
                    {totalSurcharge1.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}{' '}
                    USD
                  </p>
                </div>
                <div className="mt-1.5 flex items-center justify-between">
                  <p className="text-slate-500">Total Fuel Value:</p>
                  <p className="font-semibold">
                    {totalFscValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}{' '}
                    USD
                  </p>
                </div>
                <div className="mt-1.5 flex items-center justify-between">
                  <p className="text-slate-500">Total Extra 2:</p>
                  <p className="font-semibold">
                    {totalSurcharge2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}{' '}
                    USD
                  </p>
                </div>
                <div className="mt-1.5 flex items-center justify-between">
                  <p className="text-slate-500">Total Vat Value:</p>
                  <p className="font-semibold">
                    {totalVatValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}{' '}
                    USD
                  </p>
                </div>
                <div className="mt-1.5 flex items-center justify-between">
                  <p className="text-slate-500">Total Extra 3:</p>
                  <p className="font-semibold">
                    {totalSurcharge3.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}{' '}
                    USD
                  </p>
                </div>
                <div className="mt-1.5 flex items-center justify-between">
                  <p className="text-slate-500">Total Value in USD: </p>
                  <p className="font-semibold text-xl text-[darkblue]">
                    {totalAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD
                  </p>
                </div>
                <div className="mt-1.5 flex items-center justify-between">
                  <p className="text-slate-500">Exchange currency: </p>
                  <input
                    type="text"
                    className="bg-slate-100 w-[120px] rounded-[10px] border-slate-300 border-solid border-[1px] text-xl px-2.5 p-1"
                    value={currencyExchange}
                    onChange={(e) => setCurrencyExchange(e.target.value)}
                  />
                </div>
                <div className="my-1.5 flex items-center justify-between">
                  <p className="text-slate-500">Total Value in VND: </p>
                  <p className="font-semibold text-xl text-[slateblue]">
                    {totalVND.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} VND
                  </p>
                </div>
              </div>
              <div
                className="sticky bottom-0 bg-white z-10 p-2 px-3"
                style={{ borderTop: '1px solid rgb(230,230,230)' }}
              >
                <div className="flex items-center">
                  <div className="flex items-center">
                    <MdCreditCard size={23} />
                    <p className="ml-1.5">Pay shipments:</p>
                  </div>
                  <p className="ml-auto text-emerald-600 underline text-[19px]">
                    {totalPaid.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD
                  </p>
                </div>
                <div className="flex items-center">
                  <div className="flex items-center">
                    <MdCreditCardOff size={23} />
                    <p className="ml-1.5">Un paid shipments:</p>
                  </div>
                  <p className="ml-auto text-rose-500 underline text-[19px]">
                    {totalUnpaid.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="m-1 mt-0 mx-3.5 bg-white shadow-[rgba(0,0,0,0.05)_0px_1px_1px_0px,rgba(0,0,0,0.05)_0px_1px_2px_0px] rounded-[10px] table-wrapper2 flex flex-col">
          <div className="shadow-[rgba(0,0,0,0.1)_2.4px_2.4px_3.2px] sticky top-0 z-10 bg-white">
            <div
              className="shadow-[rgba(0,0,0,0.2)_2.4px_2.4px_3.2px]"
              style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
            >
              <div className="flex items-center">
                <div
                  className={`m-1.5 p-[7px] pr-2 bg-slate-200 rounded-[10px] hover:bg-slate-300 active:bg-slate-400 select-none flex items-center font-semibold ${
                    selected.length === 0 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                  }`}
                  onClick={() => {
                    if (selected.length === 0) return;
                    if (user.role === 'CEO') {
                      setDisplayUpdate(!displayUpdate);
                      setUpdateStatus('controller');
                    } else if (user.role === 'Admin') {
                      setDisplayUpdate(!displayUpdate);
                      setUpdateStatus('controller');
                    }
                  }}
                >
                  <LuUserSquare2 size={23} />
                  <p className="ml-1.5">Update controller</p>
                </div>
                <div
                  className={`m-1.5 ml-0 p-[7px] pr-2 bg-slate-200 rounded-[10px] hover:bg-slate-300 active:bg-slate-400 select-none flex items-center font-semibold ${
                    selected.length === 0 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                  }`}
                  onClick={() => {
                    if (selected.length === 0) return;
                    if (user.role === 'CEO') {
                      setDisplayUpdate(!displayUpdate);
                      setUpdateStatus('class');
                    } else if (user.role === 'Admin') {
                      setDisplayUpdate(!displayUpdate);
                      setUpdateStatus('class');
                    }
                  }}
                >
                  <MdHotelClass size={23} />

                  <p className="ml-1.5">Update customer class</p>
                </div>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setDisplayStatus(false);
                  }}
                >
                  <div className="relative">
                    <div
                      className="m-1.5 ml-0 p-[7px] pr-2 cursor-pointer bg-white border-solid border-slate-300 border-[1px] rounded-[10px] hover:bg-slate-300 active:bg-slate-400 select-none flex items-center font-semibold"
                      onClick={() => {
                        setDisplayStatus(!displayStatus);
                      }}
                    >
                      <MdPayment size={23} />
                      <p className="mx-1.5">{statusInput !== '' ? `${statusInput} shipments` : 'Payment status'}</p>
                      <FaSortDown size={23} className="mt-[-10px]" />
                    </div>
                    {displayStatus && (
                      <div className="absolute bg-white shadow-[rgba(0,0,0,0.1)_0px_4px_6px_-1px,rgba(0,0,0,0.06)_0px_2px_4px_-1px] w-full rounded-[10px] mt-1 z-10">
                        <div
                          className="p-1.5 m-1 rounded-[10px] flex items-center justify-between hover:bg-slate-100 active:bg-slate-200 cursor-pointer"
                          onClick={() => {
                            setStatusInput('');
                            setDisplayStatus(false);
                          }}
                        >
                          <p>All</p>
                          <div className="w-[23px] h-[23px] rounded-full border-slate-300 border-solid border-[1px] flex items-center justify-center select-none">
                            {statusInput === '' && <div className="w-[13px] h-[13px] rounded-full bg-sky-600"></div>}
                          </div>
                        </div>
                        <div
                          className="p-1.5 m-1 rounded-[10px] flex items-center justify-between hover:bg-slate-100 active:bg-slate-200 cursor-pointer"
                          onClick={() => {
                            setStatusInput('Un Paid');
                            setDisplayStatus(false);
                          }}
                        >
                          <p>Un Paid</p>
                          <div className="w-[23px] h-[23px] rounded-full border-slate-300 border-solid border-[1px] flex items-center justify-center select-none">
                            {statusInput === 'Un Paid' && (
                              <div className="w-[13px] h-[13px] rounded-full bg-sky-600"></div>
                            )}
                          </div>
                        </div>
                        <div
                          className="p-1.5 m-1 rounded-[10px] flex items-center justify-between hover:bg-slate-100 active:bg-slate-200 cursor-pointer"
                          onClick={() => {
                            setStatusInput('Pay');
                            setDisplayStatus(false);
                          }}
                        >
                          <p>Pay</p>
                          <div className="w-[23px] h-[23px] rounded-full border-slate-300 border-solid border-[1px] flex items-center justify-center select-none">
                            {statusInput === 'Pay' && <div className="w-[13px] h-[13px] rounded-full bg-sky-600"></div>}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </OutsideClickHandler>
                <div className="ml-auto flex items-center">
                  <div className="flex items-center m2-2">
                    <div className="flex items-center">
                      <BsFillFuelPumpFill size={20} />
                      <p className="ml-2">FSC:</p>
                    </div>
                    <div className="mx-1 p-1 bg-slate-100 px-2 rounded-[5px]">
                      <p>DHL: {surchargeData.dhl}%</p>
                    </div>
                    <div className="mx-1 p-1 bg-slate-100 px-2 rounded-[5px]">
                      <p>FIP: {surchargeData.fedex}%</p>
                    </div>
                    <div className="mx-1 p-1 bg-slate-100 px-2 rounded-[5px]">
                      <p>UPS: {surchargeData.ups}%</p>
                    </div>
                    <div className="mx-1 p-1 bg-emerald-50 px-2 text-emerald-600 rounded-[5px] font-semibold">
                      <p>
                        Currency: <span className="underline">{surchargeData.exchange}</span>
                      </p>
                    </div>
                  </div>
                  {selected.length !== 0 && (
                    <div className="m-1.5 p-[7px] pr-2 cursor-pointer bg-emerald-500 text-white border-solid border-transparent border-[1px] rounded-[10px] px-3 select-none flex items-center font-semibold">
                      <p>{selected.length} selected</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <Checkbox />
              <div className="w-[25%] p-2  border-slate-300 border-solid border-r-[1px] font-semibold flex items-center">
                <p>Customer</p>
                <div className="border-slate-300 border-solid border-[1px] w-[25px] h-[25px] rounded-full flex items-center justify-center ml-auto hover:bg-slate-100 active:scale-90 cursor-pointer">
                  <FaSortDown size={20} className="mt-[-7px]" />
                </div>
              </div>
              <div
                ref={classRef}
                className="w-[7%] p-2 border-slate-300 border-solid border-r-[1px] font-semibold flex relative"
              >
                <p>Class</p>
                <div
                  className="border-slate-300 border-solid border-[1px] w-[25px] h-[25px] rounded-full flex items-center justify-center ml-auto hover:bg-slate-100 active:scale-90 cursor-pointer"
                  onClick={() => setDisplayClass(!displayClass)}
                >
                  <FaSortDown size={20} className="mt-[-7px]" />
                </div>
                {currentClass !== '' && (
                  <div className="absolute top-[-30px] right-[-20px] bg-rose-600 text-white rounded-[3px] flex items-center p-1 max-w-[150px] cursor-pointer">
                    <FaFilter size={17} />
                    <p className="text-[14px] ml-1">{currentClass}</p>
                  </div>
                )}
                {displayClass && (
                  <div className="w-[300px] absolute mt-9 bg-white shadow-[rgba(0,0,0,0.2)_0px_4px_12px] ml-[-5px] rounded-[5px] max-h-[250px] overflow-auto">
                    <div className="p-2">
                      {duplicateClass.map((item, index) => (
                        <div
                          className="p-1.5 hover:bg-slate-200 active:bg-slate-300 px-2.5 rounded-[5px] cursor-pointer"
                          onClick={() => {
                            setCurrentClass(item);
                            setDisplayClass(false);
                          }}
                        >
                          <p>{item}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="w-[9%] p-2 border-slate-300 border-solid border-r-[1px] font-semibold flex items-center">
                <p>MAKH</p>
                <div className="border-slate-300 border-solid border-[1px] w-[25px] h-[25px] rounded-full flex items-center justify-center ml-auto hover:bg-slate-100 active:scale-90 cursor-pointer">
                  <FaSortDown size={20} className="mt-[-7px]" />
                </div>
              </div>
              <div className="w-[20%] p-2 border-slate-300 border-solid border-r-[1px] font-semibold flex items-center">
                <p>Location</p>
                <div className="border-slate-300 border-solid border-[1px] w-[25px] h-[25px] rounded-full flex items-center justify-center ml-auto hover:bg-slate-100 active:scale-90 cursor-pointer">
                  <FaSortDown size={20} className="mt-[-7px]" />
                </div>
              </div>
              <div className="w-[20%] p-2 border-slate-300 border-solid border-r-[1px] font-semibold flex items-center">
                <p>Contact name</p>
                <div className="border-slate-300 border-solid border-[1px] w-[25px] h-[25px] rounded-full flex items-center justify-center ml-auto hover:bg-slate-100 active:scale-90 cursor-pointer">
                  <FaSortDown size={20} className="mt-[-7px]" />
                </div>
              </div>
              <div className="w-[5%] p-2 font-semibold border-slate-300 border-solid border-r-[1px] text-center">
                <p>Amt</p>
              </div>
              <div className="w-[10%] p-2 font-semibold text-center ">
                <p>Action</p>
              </div>
            </div>
          </div>
          {loading && (
            <div className="flex flex-col items-center self-center">
              <CircularProgress size={35} style={{ marginTop: 20, marginBottom: 20 }} />
              <p className="text-lg text-slate-500">Loading customer</p>
            </div>
          )}
          {currentCustomers.length === 0 && !loading && (
            <div className="flex items-center justify-center p-5 flex-col">
              <p className="text-xl">No customers found or system is fetching, please wait</p>
              <div
                className="mt-2 p-[7px] bg-sky-600 text-white font-semibold px-3.5 rounded-[7px] select-none hover:bg-sky-700 active:bg-sky-800 cursor-pointer flex items-center"
                onClick={() => {
                  window.location.reload();
                }}
              >
                <FiRefreshCw size={22} className="mr-2" />
                <p>Reload now</p>
              </div>
            </div>
          )}
          {currentCustomers.length !== 0 &&
            !loading &&
            currentCustomers.map((item) => {
              const shipmentPerCustomer = getShipment.filter((s) => {
                return s.makh === item.makh;
              });
              return (
                <div
                  className={`flex items-center hover:shadow-[rgba(0,0,0,0.25)_1.95px_1.95px_2.6px] active:bg-slate-300 cursor-pointer ${
                    item.select ? 'bg-sky-50' : ''
                  }`}
                >
                  <Checkbox
                    style={{ paddingTop: 5, paddingBottom: 5 }}
                    checked={item.select}
                    onChange={(e) => {
                      setSelectedCustomer(
                        customers.map((sd) => {
                          let value = e.target.checked;
                          if (sd.uid === item.uid) {
                            sd.select = value;
                          }
                          return sd;
                        })
                      );
                    }}
                  />
                  <div
                    className="w-[25%] p-2  border-slate-300 border-solid border-r-[1px] border-b-[1px] flex items-center font-semibold"
                    onClick={() => {
                      setDisplayCustomerDetail(true);
                      setFilterMakh(item.makh);
                      setCustomerDetail(item);
                    }}
                  >
                    <p className="w-full whitespace-nowrap overflow-hidden text-ellipsis">{item.exporter}</p>
                  </div>
                  <div className="w-[7%] p-2 border-slate-300 border-solid border-r-[1px] text-slate-500 flex items-center">
                    <p className="overflow-hidden whitespace-nowrap text-ellipsis w-full">{item.plan}</p>
                  </div>
                  <div className="w-[9%] p-2 border-slate-300 border-solid border-r-[1px] text-slate-500 flex items-center">
                    <p className="overflow-hidden whitespace-nowrap text-ellipsis w-full text-black">{item.makh}</p>
                  </div>
                  <div className="w-[20%] p-2 border-slate-300 border-solid border-r-[1px] text-[darkblue] flex items-center">
                    <FaLocationDot size={23} className="mr-1.5" />
                    <p className="w-full overflow-hidden whitespace-nowrap text-ellipsis">{item.address}</p>
                  </div>
                  <div className="w-[20%] p-2 border-slate-300 border-solid border-r-[1px] flex items-center">
                    <p className="w-[100%] overflow-hidden whitespace-nowrap text-ellipsis">{item.displayName}</p>
                  </div>
                  <div className="w-[5%] p-2 border-slate-300 border-solid border-r-[1px] text-center">
                    {statusInput === 'Un Paid' && (
                      <p className="w-[100%] overflow-hidden whitespace-nowrap text-ellipsis text-rose-500">
                        - {shipmentPerCustomer.length}
                      </p>
                    )}
                    {statusInput === 'Pay' && (
                      <p className="w-[100%] overflow-hidden whitespace-nowrap text-ellipsis text-emerald-500">
                        + {shipmentPerCustomer.length}
                      </p>
                    )}
                    {statusInput === '' && (
                      <p className="w-[100%] overflow-hidden whitespace-nowrap text-ellipsis text-black">
                        {shipmentPerCustomer.length}
                      </p>
                    )}
                  </div>
                  <div className="w-[10%] p-2 font-semibold flex items-center gap-x-3.5 justify-center">
                    <BsEyeSlash
                      size={25}
                      className="bg-sky-50 text-sky-600 rounded-full active:scale-90"
                      onClick={() => {
                        setDisplayCustomerDetail(true);
                        setFilterMakh(item.makh);
                        setCustomerDetail(item);
                      }}
                    />
                    <MdCloudDownload
                      size={25}
                      className="bg-slate-100 text-black rounded-full active:scale-90"
                      onClick={() => {
                        setDisplayExport(true);
                        setFilterMakh(item.makh);
                        setCustomerDetail(item);
                      }}
                    />
                    <FaRegTrashCan size={25} className="bg-rose-50 text-rose-500 rounded-full active:scale-90" />
                  </div>
                </div>
              );
            })}
          {!loading && (
            <div className="sticky bottom-0 bg-white z-10 mt-auto shadow-[0px_-4px_3px_rgba(50,50,50,0.075)]">
              <div className="paginate">
                <IconButton
                  type="button"
                  disabled={currentPage == pages[0] ? true : false}
                  style={{ width: 35, height: 35, marginLeft: 5, marginRight: 5 }}
                  onClick={() => handleFirstPage()}
                >
                  <MdKeyboardDoubleArrowLeft className="prev-btn-page" size={40} />
                </IconButton>
                <IconButton
                  type="button"
                  disabled={currentPage == pages[0] ? true : false}
                  style={{ width: 35, height: 35, marginRight: 5 }}
                  onClick={() => handlePrevPage()}
                >
                  <MdKeyboardArrowLeft className="prev-btn-page" size={40} />
                </IconButton>
                {renderPageNumbers}
                <IconButton
                  type="button"
                  disabled={currentPage == pages[pages.length - 1] ? true : false}
                  style={{ width: 35, height: 35 }}
                  onClick={() => handleNextPage()}
                >
                  <MdKeyboardArrowRight className="prev-btn-page" />
                </IconButton>
                <IconButton
                  type="button"
                  disabled={currentPage == pages[pages.length - 1] ? true : false}
                  style={{ width: 35, height: 35 }}
                  onClick={() => handleLastPage()}
                >
                  <MdKeyboardDoubleArrowRight className="prev-btn-page" />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
